import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Checklist = makeShortcode("Checklist");
const CTA = makeShortcode("CTA");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started`}</h1>
    <p>{`Organizing a successful event takes a lot of coordination.`}</p>
    <p>{`This guide will walk you through the process of planning a meetup and link to resources developed by the community to help plan your event.`}</p>
    <h2>{`Planning`}</h2>
    <p>{`Here's a list of steps to take when planning an event:`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Decide whether the event will be physical or virtual.`}</p>
      <p>{`Create an `}<a parentName="p" {...{
          "href": "/work_with_us/meetups/event-formats-guide/"
        }}>{`agenda.`}</a></p>
      <p>{`Figure out the `}<a parentName="p" {...{
          "href": "/work_with_us/meetups/budget-guide/"
        }}>{`budget.`}</a></p>
      <p>{`Determine the time and location of the event`}</p>
      <p>{`If the event will be virtual, be sure to find a suitable platform for hosting.`}</p>
      <p>{`Coordinate resources to record the event`}</p>
      <p>{`Create a plan for `}<a parentName="p" {...{
          "href": "/contribute/meetups/promotion-guide/"
        }}>{`promoting`}</a>{` the event.`}</p>
      <p>{`Read through the `}<a parentName="p" {...{
          "href": "https://airtable.com/shr6Icuj6tOy0k55l"
        }}>{`Feedback Form`}</a>{` before the event to prepare to answer the questions.`}</p>
    </Checklist>
    <p>{`Once you have your meetup plan ready, speakers confirmed, the venue set, and the event promoted, it's time to make it happen!`}</p>
    <CTA mdxType="CTA">
      <p>{`Apply for a Dai grant to help fund your meetup using the `}<a parentName="p" {...{
          "href": "https://airtable.com/shr4HOtcZ8o3VZmek"
        }}>{`Community Meetup Form`}</a></p>
    </CTA>
    <p>{`To qualify for funding, we require hosts to provide feedback following their event using our `}<a parentName="p" {...{
        "href": "https://airtable.com/shr6Icuj6tOy0k55l"
      }}>{`Feedback Form`}</a>{`.`}</p>
    <p>{`This helps us to better understand the impact of these events and what we can do to help make them better.`}</p>
    <h2>{`Pre-Meetup`}</h2>
    <p>{`To get your audience excited for the meetup, consider:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Providing light snacks and beverages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hosting a contest to provide the best explanation of Dai in under a minute.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Providing art supplies and encouraging attendees to create graphics describing Dai, Maker Governance, MIPs, Vaults, or any other aspect of the Maker Protocol.`}</p>
      </li>
    </ul>
    <h2>{`Event Day`}</h2>
    <p>{`On the day of the event, here's what needs to get accomplished:`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Send communications to all the essential parties early in the day.`}</p>
      <p>{`Repeat promotional messaging across all relevant channels on the day of the event.`}</p>
      <p>{`Read through the `}<a parentName="p" {...{
          "href": "https://airtable.com/shr6Icuj6tOy0k55l"
        }}>{`Feedback Form`}</a>{` to get familiar with the various details to keep track of.`}</p>
      <p>{`Arrive at the venue early to prepare and test the equipment.`}</p>
      <p>{`During the introduction, describe the agenda, introduce the speakers, and talk about the key takeaways participants can expect from the event.`}</p>
      <p>{`Be welcoming and informative`}</p>
    </Checklist>
    <h2>{`Resources`}</h2>
    <p>{`Additional guides and resources for hosting events include:`}</p>
    <List mdxType="List">
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "/contribute/meetups/budget-guide/"
            }}>{`Budget Guide`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "/contribute/meetups/promotion-guide/"
            }}>{`Promotion Guide`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "/contribute/meetups/event-formats-guide/"
            }}>{`Event Formats Guide`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://brand.makerdao.com"
            }}>{`Design files`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://github.com/makerdao/awesome-makerdao#videos"
            }}>{`Example Presentations`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://github.com/ryancreatescopy/community/tree/203253ec20549aa3667729d5b12a0ee7c5834bde/meetups/assets/slides/README.md"
            }}>{`Slide Templates and Presentation Decks`}</a></p>
        </li>
      </ul>
    </List>
    <h2>{`Virtual Event Platforms`}</h2>
    <p>{`Here are some video streaming platforms capable of hosting virtual events:`}</p>
    <List mdxType="List">
      <ul>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.airmeet.com/"
            }}>{`Airmeet`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://www.crowdcast.io/"
            }}>{`Crowdcast`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://zoom.us/"
            }}>{`Zoom`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><a parentName="p" {...{
              "href": "https://meet.google.com/"
            }}>{`Google Meets`}</a></p>
        </li>
      </ul>
    </List>
    <h2>{`Helpful Tips`}</h2>
    <p>{`Organizers have found these to be helpful tips when planning their events.`}</p>
    <List mdxType="List">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Plan on paper before doing anything else`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Find people willing to help with the event`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Write a budget and update it as things get locked things in`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`When scouting a location, ask for a breakdown of available equipment.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Consider the audience and plan the meetup in a way that suits their needs.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Be the catalyst and facilitator, not the featured speaker at every meeting `}{`(`}{`people will get bored.`}{`)`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`When hosting a regular event, set a consistent date, time, and location.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Include information about the speakers, on promotional materials.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Shake up the format! Have presentations, panel discussions, roundtables, workshops, etc...`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Avoid canceling meetups`}</p>
        </li>
      </ul>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      